.container {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header{
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-height: 55px;
}

.chatInput{
  background-color:#D6D6D6;
  position:absolute;
  left:0px;
  right:0px;
  bottom:0px;
  height:50px;
  display:flex;
  align-items:center;
  border-bottom-right-radius:8px;
  border-bottom-left-radius:8px
}

.commentInput{
  background-color:#D6D6D6;
  height:120px;
  padding: 5px;
  outline: none;
  border-radius:8px;
  border: none;
  vertical-align: text-top;
  display: flex;
  width: 60%;
  background-color: white;
}

.myMessage{
  min-width: 100px;
  display: flex;
  flex-direction: column;
  background-color: #7A63FF;
  border-radius: 8px;
  width: 70%;
  margin-top: 12px;
  align-self: flex-end;
}

.otherMessage{
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background-color: #D6D6D6;
  border-radius: 8px;
  width: 70%;
  margin-top: 12px;
  
}

.otherMessageText{
  padding-top: 8px;
  margin-left: 8px;
  color: black;
  word-wrap: break-word;
  padding-right: 8px;
}

.myMessageText{
  padding-top: 8px;
  margin-left: 8px;
  color: white;
  word-wrap: break-word;
  padding-right: 8px;
}

.otherMessageTime{
  align-self: flex-end;
  margin-right: 8px;
  padding-bottom: 8px;
  color: #7B7B7B;
  margin-top: 8px;
  font-size: 0.6rem;
}


.messageTime{
  align-self: flex-end;
  margin-right: 8px;
  padding-bottom: 8px;
  color: white;
  margin-top: 8px;
  font-size: 0.6rem;
}

.buttonIcon{
  width: 35px;
  height: 35px;
}

.content{
  display: flex;
  align-self: stretch;
  height: max-content;
  flex:12;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  overflow: hidden;

}

.chatHeader{
  display: flex;
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  border-bottom-color: #F5F5F5;
}

.chatContainer{
  flex:1;
  background-color: white;
  border-radius: 8px;
  margin-left: 15px;
  position: relative;
}

.contentLeft{
  
  display: flex;
  flex:4;
  flex-direction: column;
  height: 95%;
}

.contentRight{
  flex:4;
  height: 95%;
  flex-direction: column;
  overflow: scroll;
  
}

.contentMid{
  position: relative;
  display: flex;
  height: 100%;
  flex:5;
  justify-content: center;
  align-items: center;
}

.messageInput{
  margin-left: 8px;
  margin-right: 8px;
  border: none;
  border-radius: 12px;
  height: 30px;
  font-size: 16px;
  color:#7A63FF;
  flex:1;
  display:flex;
  background-color: white;
  padding-right: 4px;
  outline: none;
}

.shareRemote{
  z-index: 999;
  width: 12vh;
  height: 18vh;
  background-color: white;
  position: absolute;
  overflow: hidden;
  bottom: 6%;
  left: 6%;
  border-radius: 10px;
  overflow: hidden;
}

.screenShare{
  position: relative;
  border-radius: 10px;
  background-color: white;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.remote{
  min-width: 480px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  width: 90%;
  height: 95%;
}

.local{
  z-index: 999;
  width: 13vh;
  height: 20vh;
  background-color: white;
  position: absolute;
  bottom: 6%;
  right: 6%;
  border-radius: 10px;
  overflow: hidden;
}

.closeButton{
  cursor: pointer;
  width: 65px;
  height: 65px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #FF3C3C;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.button{
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #7A63FF;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.fotterLeft{
  display: flex;
  flex:3;
  justify-content: center;
  align-items: center;
  
}

.fotterRight{
  display: flex;
  flex:3;
  justify-content: center;
  align-items: center;
}

.fotterMid{
  display: flex;
  flex:4;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.suggestion{
  width: 90%;
  display: flex;
  border-radius: 10px;
  margin-left: 5%;
  height: 75px;
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.footer{
  display: flex;
  flex:1;
}

.mobileSuggestContainer{
  z-index: 1000;
  position:absolute;
  bottom:0px;
  width:100vw;
  height:130px;
  display:flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow:hidden;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  box-shadow: rgba(0,0,0,0.15) 0px -5px 30px;
}

.suggestCont{
  display:flex;
  overflow:scroll;
  flex:2.2;
}

.selffixIcon{
  height: 30px;
  margin-left: 50px;
}

@media screen and (max-width: 1000px) {


  .container{
    height: 100vh;
  }

  .header{
    min-height: 60px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .remote{
    background-color: white;
    width: 100%;
  }

  .contentLeft{
    flex:0.3
  }

  .chatInput{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px;
  }

  .contentRight{
    flex:0.3
  }

  .chatContainer{
    position: absolute;
    z-index: 999999;
    left:0px;
    right: 0px;
    bottom:0px;
    top:100px;
    margin-left: 0px;
  }

  .suggestion{
    flex: 0 0 90%;
    margin-left: 2%;
    margin-right: 1%;
    box-shadow: rgba(100, 100, 111, 0) 0px 7px 29px 0px;
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #E6E6E6;
    margin-top:0px;
  }

  .selffixIcon{
    height: 35px;
    margin-left: 20px;
  }
  
}

.mobileSuggestContainer::-webkit-scrollbar {
  display: none;
}

.suggestCont::-webkit-scrollbar {
  display: none;
}